import React, { useState, Fragment } from 'react';
import { Form, Button, Checkbox, Input, Row, Col } from 'antd';
import FileUpload from '../fileUpload';
import QuillWrapper from '../quillWrapper';

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 5 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 19 }
	}
};

const tailLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0
		},
		sm: {
			span: 12,
			offset: 5
		}
	}
};

const AddUpdateStationForm = ({ selected, onCreate, onUpdate, onClose, isAdmin }) => {
	const [form] = Form.useForm()

	const [ images, setImages ] = useState(selected ? selected.images : []);
	const [ logo, setLogo ] = useState(selected ? selected.logo : null);

	const [ message, setMessage ] = useState(selected ? selected.description : '');

	const onSubmit = (values) => {
		// remove spaces and split on comma
		if (values.ip) values.ip = values.ip.replace(/[\s]+/g, '').split(',');
		if (selected) {
			onUpdate({
				...selected,
				...values,
				description: message,
				images,
				logo
			});
		} else {
			onCreate({
				...values,
				description: message,
				images,
				logo
			});
		}
	};

	const onCompleteUploadLogo = (url) => {
		setLogo(url);
	};
	const onRemoveLogo = () => {
		setLogo(null);
	};

	return (
		<Form
			form={form}
			{...formItemLayout}
			initialValues={{
				name: selected ? selected.name : '',
				default: selected ? selected.default : false,
				description: selected ? selected.description : '',
				shortDescription: selected ? selected.shortDescription : '',
				ip: selected ? (selected.ip ? selected.ip.join(',') : '') : '',
				phoneNumber: selected ? selected.phoneNumber : '',
				whatsappNumber: selected ? selected.whatsappNumber : '',
				slogan: selected ? selected.slogan : ''
			}}
			onFinish={onSubmit}
		>
			<Form.Item label="Naam station" name="name" rules={[ { required: true, message: 'naam is verplicht' } ]}>
				<Input />
			</Form.Item>

			<Form.Item
				label="Telefoonnummer"
				name="phoneNumber"
				rules={[ { required: selected ? !selected.default : false, message: 'Telefoonnummer is verplicht' } ]}
			>
				<Input />
			</Form.Item>

			{ isAdmin &&
				<Fragment>
					<Form.Item label="IP adres station" name="ip">
						<Input />
					</Form.Item>
					<Row>
						<Col xs={{ span: 24, offset: 0 }} sm={{ span: 19, offset: 5 }}>
							<p>
								<i>Vul meerdere ip adressen komma gescheiden in. eg: 123.456.789,123.456.788</i>
							</p>
						</Col>
					</Row>
				</Fragment>
			}

			<Form.Item label="Whatsapp nummer" name="whatsappNumber">
				<Input />
			</Form.Item>
			<Row>
				<Col xs={{ span: 24, offset: 0 }} sm={{ span: 19, offset: 5 }}>
					<p>
						<i>Whatsapp nummer met landnummer en plus teken invullen: +31612345678</i>
					</p>
				</Col>
			</Row>

			<Row>
				<Col xs={{ span: 24, offset: 0 }} sm={{ span: 19, offset: 5 }}>
					<p>
						<i>Als deze gevuld is zal er een whatsapp knop getoond worden.</i>
					</p>
				</Col>
			</Row>

			<Form.Item label="Slogan" name="slogan">
				<Input />
			</Form.Item>

			<Row>
				<Col xs={{ span: 24, offset: 0 }} sm={{ span: 19, offset: 5 }}>
					<p>
						<i>Vanaf hier onder je stations pagina</i>
					</p>
				</Col>
			</Row>

			<Form.Item label="Korte intro" name="shortDescription">
				<Input />
			</Form.Item>

			<Form.Item label="Omschrijving" name="description">
				<QuillWrapper text={message} submissionHandler={setMessage}/>
			</Form.Item>

			{ isAdmin &&
				<Row style={{ marginBottom: 16 }}>
					<Col style={{ textAlign: 'right'}} xs={{ span: 24, offset: 0 }} sm={{ span: 5, offset: 0 }}>
						<span style={{ marginRight: '8px' }}>Logo:</span>
					</Col>
					<Col xs={{ span: 24, offset: 0 }} sm={{ span: 19, offset: 0 }}>
						<FileUpload
							multiple={false}
							images={logo ? [ logo ] : []}
							onComplete={onCompleteUploadLogo}
							onRemove={onRemoveLogo}
						/>
					</Col>
				</Row>
			}
			
			{ isAdmin &&
				<Form.Item {...tailLayout} name="default" valuePropName="checked">
					<Checkbox>Standaard station</Checkbox>
				</Form.Item>
			}

			<Form.Item {...tailLayout}>
				<Button htmlType="submit" type="primary">
					Opslaan
				</Button>
			</Form.Item>
		</Form>
	);
};

export default AddUpdateStationForm;
