import React, { useState, useEffect, useContext } from 'react';

import AdminLayout from '../../layouts/admin';
import SEO from '../../components/seo';

import StationsTable from '../../components/admin/stations/stationsTable';
import AddUpdateStationForm from '../../components/admin/stations/addUpdateStationForm';

import { StationContext } from '../../contexts/stationContext';
import { AuthContext } from '../../components/auth/authContext';

import Loader from '../../components/loader';

import { Modal, Button, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const AdminStationsPage = ({ location }) => {
	const { state: authState } = useContext(AuthContext);

	const {
		stations,
		stationsLoading,
		getStations,
		createStation: createItem,
		stationCreating,
		stationCreatingError,
		updateStation: updateItem,
		stationUpdating,
		stationUpdatingError,
		removeStation: removeItem,
		stationRemoving,
		stationRemovingError
	} = useContext(StationContext);

	const [ station, setStation ] = useState(null);
	const [ showModal, setShowModal ] = useState(false);

	useEffect(() => {
		getStations();
	}, []);

	const selectStation = (stationToSelect) => {
		setStation(stationToSelect);
		setShowModal(stationToSelect != null);
	};

	const createStation = async (stationToCreate) => {
		const result = await createItem(stationToCreate);
		if (!result.errors) {
			setShowModal(false);
			selectStation(null);
		} else {
			message.error(result.errors.map(e => e.message).join('. '));
		}
	};

	const updateStation = async (stationToUpdate) => {
		const result = await updateItem(stationToUpdate);
		if (!result.errors) {
			setShowModal(false);
			selectStation(null);
		} else {
			message.error(result.errors.map(e => e.message).join('. '));
		}
	};

	const removeStation = async (stationToDelete) => {
		Modal.confirm({
			title: 'Weet je het zeker',
			icon: <ExclamationCircleOutlined />,
			content: `Weet je zeker dat je '${stationToDelete.name}' wilt verwijderen? Dit kan niet ongedaan gemaakt worden.`,
			onOk: async () => {
				const removed = await removeItem(stationToDelete);
				if (removed) {
					selectStation(null);
				} else {
					message.error('Er ging iets mis met het verwijderen.');
				}
			}
		});
	};

	return (
		<AdminLayout path={location.pathname} breadcrumbs={[ { link: '/admin/stations', title: 'Stations' } ]}>
			<SEO title="Admin stations" />

			<h1>
				Stations
				{authState.isAdmin && (
					<Button type="default" onClick={() => setShowModal(true)} disabled={station}>
						Station toevoegen
					</Button>
				)}
			</h1>

			{stationsLoading ? (
				<Loader />
			) : (
				<StationsTable
					stationId={authState.stationId}
					isAdmin={authState.isAdmin}
					stations={stations}
					onSelect={selectStation}
					onRemove={removeStation}
				/>
			)}

			{showModal && (
				<Modal
					title="Station toevoegen/wijzigen"
					visible={true}
					onCancel={() => selectStation(null)}
					footer={null}
					width={'90vw'}
				>
					<AddUpdateStationForm
						isAdmin={authState.isAdmin}
						selected={station}
						onCreate={createStation}
						onUpdate={updateStation}
						onClose={() => setShowModal(false)}
					/>
				</Modal>
			)}
		</AdminLayout>
	);
};

export default AdminStationsPage;
