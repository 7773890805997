import React from 'react';
import { Button, Space, Table, Tag } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const StationsTable = ({ stations, stationId = '', isAdmin = false, onSelect, onRemove }) => {
	return (
		<Table
			columns={[
				{
					title: 'Name',
					dataIndex: 'name',
					key: 'name'
				},
				{
					title: 'Standaard',
					key: 'default',
					dataIndex: 'default',
					render: (isDefault) => (
						<Tag color={isDefault ? 'green' : 'red'}>{isDefault ? 'Standaard' : 'Niet standaard'}</Tag>
					)
				},
				{
					title: 'Action',
					key: 'action',
					className: 'column-align-right',
					render: (text, record) => {
						return (
							<Space>
								{(isAdmin || record._id === stationId) && (
										<Button
											style={{ float: 'right' }}
											type="primary"
											shape="circle"
											icon={<EditOutlined />}
											onClick={() => onSelect(record)}
										/>
									)}
								{isAdmin && (
									<Button
										style={{ float: 'right' }}
										type="primary"
										shape="circle"
										icon={<DeleteOutlined />}
										onClick={() => onRemove(record)}
										danger
									/>
								)}
							</Space>
						);
					}
				}
			]}
			rowKey="_id"
			dataSource={stations}
		/>
	);
};
export default StationsTable;
