import React, { Fragment, Component } from "react";
// import ReactQuill from "react-quill";
import { Modal, Button } from 'antd';
import FileUpload from './fileUpload';
import 'react-quill/dist/quill.snow.css';

const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;

export default class QuillWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: props.text || null,
            imagePopup: false,
            image: null
        };
        this.quillRef = null;
    }

    togglePopup = () => {
        this.setState((state) => ({  imagePopup: !state.imagePopup } ));
    }

    handleChange = (content, delta, source, editor) => {
       this.setState({
           text: content
       });
       // also update the top level submission
       this.props.submissionHandler(content);
    };

    imageHandler =  () => {
        this.togglePopup();
    }

    insertImage = (url) => {
        if (url) {
            const cursorPosition = this.quillRef.getEditor().getSelection(true).index;
            this.quillRef.getEditor().insertEmbed(cursorPosition, 'image', url, 'user');
            this.togglePopup();
        } else {
            alert('Upload mislukt.');
        }
    }

    render() {
        return (
            <Fragment>

                <Modal
                    title="Upload image"
                    visible={this.state.imagePopup}
                    footer={[
                        <Button key="back" onClick={this.togglePopup}>
                            Terug
                        </Button>
                    ]}
                    onCancel={this.togglePopup}
                >
                    <FileUpload
						multiple={false}
						images={this.state.image ? [this.state.image] : []}
						onRemove={() => this.setState({ image: null })}
						onComplete={this.insertImage}
					/>
                </Modal>
                    
                <ReactQuill
                    ref={(el) => this.quillRef = el}
                    theme="snow"
                    modules={
                    {
                        toolbar: {
                        container: [
                            [{ 'header': [1, 2, false] }],
                            ['bold', 'italic', 'underline','strike', 'blockquote'],
                            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                            ['link', 'image'],
                        ],
                        handlers: {
                            'image': this.imageHandler
                        }
                        }
                    }
                    }
                    formats={[
                        'header',
                        'bold', 'italic', 'underline', 'strike', 'blockquote',
                        'list', 'bullet', 'indent',
                        'link', 'image'
                    ]}
                    value={this.state.text}
                    onChange={this.handleChange}
                />
            </Fragment>
        );
    }
}
